import React from "react";
import styled from "styled-components";
import { Bio } from "../../data/constants";
import {
  ChatRounded,
  GitHub,
  LinkedIn,
  DuoRounded,
  EmojiEvents, 
  AdminPanelSettings,
  Description,
} from "@mui/icons-material";

const FooterContainer = styled.div`
  width: 100%;
  padding: 2rem 0;
  display: flex;
  justify-content: center;
  z-index: 10;
  position: relative;
`;

const FooterWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  align-items: center;
  padding: 1rem;
  color: ${({ theme }) => theme.text_primary};
`;

const Logo = styled.div`
  font-weight: 600;
  font-size: 20px;
  color: ${({ theme }) => theme.primary};
`;

const Nav = styled.ul`
  width: 100%;
  max-width: 800px;
  margin-top: 0.5rem;
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: center;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
    text-align: center;
    font-size: 12px;
  }
`;

const NavLink = styled.a`
  color: ${({ theme }) => theme.text_primary};
  text-decoration: none;
  font-size: 1.2rem;
  transition: color 0.2s ease-in-out;
  position: relative;
  padding-bottom: 5px;

  &:hover::after {
    width: 100%;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: white;
    transition: width 0.5s ease;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const SocialMediaIcons = styled.div`
  display: flex;
  margin-top: 1rem;
`;

const bounce = `
  @keyframes bounce {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-8px);
    }
    100% {
      transform: translateY(0);
    }
  }
`;

const shadowEffect = `
  @keyframes shadowEffect {
    0% {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    }
    100% {
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    }
  }
`;

const SocialMediaIcon = styled.a`
  display: inline-block;
  margin: 0 1rem;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.text_primary};
  transition: color 0.2s ease-in-out;
  position: relative;

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.primary};
    animation: bounce 0.5s ease, shadowEffect 0.3s ease-in-out forwards;
  }

  ${bounce}  /* Bouncing effect */
  ${shadowEffect}  /* Shadow effect */
`;

const Copyright = styled.p`
  color: ${({ theme }) => theme.primary};
  margin-top: 1.5rem;
  font-size: 0.95rem;
  color: ${({ theme }) => theme.soft2};
  text-align: center;
  &:hover {
    color: white;
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterWrapper>
        <Logo>Uncover My Work Journey 💼</Logo>
        <Nav>
          <NavLink href="https://drive.google.com/drive/folders/1E6i2kU32qfjs5COhSTGM2LPsQJtqxtdU?usp=sharing" target="_blank">Projects</NavLink>
          <NavLink href="https://drive.google.com/drive/folders/1ZuuYqYpbE6lofig8MbIFnlsTUlqQ--KX?usp=sharing" target="_blank">Certifications</NavLink>
          <NavLink href="https://drive.google.com/drive/folders/1Powlhl0NBAKl__EEY5b6havdXQES_aGa?usp=sharing" target="_blank">Research Papers</NavLink>
          <NavLink href="https://lakshay-experience.netlify.app/" target="_blank">Experience</NavLink>
          <NavLink href="https://codolio.com/profile/LakshayD02" target="blank">Codolio</NavLink>
        </Nav>
        <SocialMediaIcons>
          <SocialMediaIcon href={Bio.linkedin} target="display">
            <LinkedIn />
          </SocialMediaIcon>
          <SocialMediaIcon href={Bio.github} target="display">
            <GitHub />
          </SocialMediaIcon>
          <SocialMediaIcon href={Bio.blogger} target="display">
            <AdminPanelSettings />
          </SocialMediaIcon>
          <SocialMediaIcon href={Bio.topmate} target="display">
            <DuoRounded />
          </SocialMediaIcon>
          <SocialMediaIcon href={Bio.leetcode} target="display">
            <EmojiEvents />
          </SocialMediaIcon>
          <SocialMediaIcon href={Bio.hackerrank} target="display">
            <Description />
          </SocialMediaIcon>
        </SocialMediaIcons>
        <Copyright>&copy; 2024 Lakshay Dhoundiyal. All rights reserved.</Copyright>
      </FooterWrapper>
    </FooterContainer>
  );
};

export default Footer;
